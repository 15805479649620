import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const SListItem = styled.li`
  color: ${COLORS.GREY};
  margin: 0;
  font-weight: 300;
  font-size: 1.125rem;

  &::marker {
    color: ${COLORS.CREAMY};
  }
`;

export const ListItem = ({ children }) => <SListItem>{children}</SListItem>;

ListItem.propTypes = {
  children: node.isRequired,
};
