import React from 'react';
import styled from 'styled-components';
import { bool, node } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const SParagraph = styled.p`
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: ${COLORS.GREY};
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? 0 : '1.25rem')};
`;

export const Paragraph = ({ children, noMarginBottom }) => (
  <SParagraph noMarginBottom={noMarginBottom}>{children}</SParagraph>
);

Paragraph.defaultProps = {
  noMarginBottom: false,
};

Paragraph.propTypes = {
  children: node.isRequired,
  noMarginBottom: bool,
};
