import React, { useEffect, useState } from 'react';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Subtitle } from 'views/offer/components/Subtitle';
import { sendVoucher } from 'api/email';
import { LINKS } from 'constants/links';
import { validateEmail } from 'utils/utils';

import SuccessModal from './components/success-modal';

export const VoucherView = ({ email }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const sendVoucherEmail = async () => {
      if (email && validateEmail(email)) {
        const res = await sendVoucher({ email, isTermsAccepted: true });

        if (res.error) {
          return;
        }
        setIsModalOpen(true);
      }
    };

    sendVoucherEmail();
  }, [email]);

  return (
    <div>
      {isModalOpen && (
        <SuccessModal email={email} closeModal={() => setIsModalOpen(false)} />
      )}
      <Header breadcrumbsLinks={[LINKS.LEXICON]}>
        Regulamin akcji – voucher o wartości 100 pln za zapis do newslettera
      </Header>
      <Content>
        <Subtitle>Spis treści</Subtitle>
        <List>
          <ListItem>ORGANIZATOR AKCJI UPRAWNIAJĄCEJ DO ZNIŻEK</ListItem>
          <ListItem>
            ZASADY AKCJI RABATOWEJ NA ZABIEGI Z ZAKRESU MEDYCYNY ESTETYCZNEJ
          </ListItem>
          <ListItem>
            POSOBY UPRAWNIONE DO SKORZYSTANIA Z OFERTY ZNIŻKOWEJ
          </ListItem>
          <ListItem>CZAS TRWANIA AKCJI I OKRES OBOWIĄZYWANIA ZNIŻEK</ListItem>
          <ListItem>POSTANOWIENIA KOŃCOWE</ListItem>
        </List>

        <Subtitle>1.ORGANIZATOR AKCJI UPRAWNIAJĄCEJ DO ZNIŻEK</Subtitle>
        <Paragraph>
          Organizatorem akcji jest IDEALE Sp. z o.o. , (dalej: Organizator) z
          siedzibą w Warszawie, ul. Sokołowska 9, lok. U-12, 01-142.
        </Paragraph>

        <Subtitle>
          2. ZASADY AKCJI RABATOWEJ NA ZABIEGI Z ZAKRESU MEDYCYNY ESTETYCZNEJ
        </Subtitle>
        <List>
          <ListItem>
            Akcja polega na udzielaniu przez Organizatora zniżki kwotowej w
            wysokości 100 zł. Możliwość uzyskania zniżki posiada osoba pierwszy
            raz zapisująca się do Newslettera IDEALE Medycyna Estetyczna.
          </ListItem>
          <ListItem>
            Zniżka komunikowana jest poprzez stronę internetową:
            <a href="https://ideale.pl">https://ideale.pl</a>
          </ListItem>
          <ListItem>
            Ze zniżki skorzystać można w siedzibie Organizatora przy ul.
            Sokołowska 9 (Centrum Medyczne Medify.me).
          </ListItem>
          <ListItem>
            Oferowana zniżka nie podlega wymianie na środki pieniężne, oraz nie
            łączy się z innymi zniżkami oferowanymi przez Organizatora np. karta
            lojalnościowa.
          </ListItem>
          <ListItem>
            Voucher można wykorzystać tylko do zakupu zabiegu z zakresu medycyny
            estetycznej. Vouchera NIE można wykorzystać do zakupu zabiegu z
            zakresu dermatochirurgi, laseroterapii oraz skleroterapii.
          </ListItem>
        </List>

        <Subtitle>
          3. OSOBY UPRAWNIONE DO SKORZYSTANIA Z OFERTY ZNIŻKOWEJ
        </Subtitle>
        <Paragraph>
          Osobą Uprawnioną do skorzystania z oferty zniżkowej podczas okresu
          trwania Akcji jest osoba, która otrzyma kupon o wartości 100 zł na
          podany do Newslettera IDEALE Medycyna Estetyczna na adres mailowy.
        </Paragraph>

        <Subtitle>4. CZAS TRWANIA AKCJI I OKRES OBOWIĄZYWANIA ZNIŻEK</Subtitle>
        <Paragraph>
          Voucher można wykorzystać przez 3 miesiące od daty otrzymania
          vouchera.
        </Paragraph>

        <Subtitle>5. POSTANOWIENIA KOŃCOWE</Subtitle>
        <List>
          <ListItem>
            Zniżki, o których mowa w niniejszym regulaminie, nie będą łączone z
            innymi zniżkami oferowanymi przez Organizatora w okresie trwania
            Akcji oraz innymi zniżkami, jakie posiada pacjent. Klient będzie
            miał prawo dokonać wyboru z której zniżki lub promocji w dniu
            konsultacji korzysta.
          </ListItem>
          <ListItem>
            Organizator zastrzega sobie prawo wprowadzenia zmian w regulaminie.
          </ListItem>
          <ListItem>Regulamin obowiązuje od dnia opublikowania.</ListItem>
          <ListItem>
            Niniejszy regulamin jest podany na stronie{' '}
            <a href="https://ideale.pl">https://ideale.pl</a>.
          </ListItem>
        </List>
      </Content>
    </div>
  );
};
