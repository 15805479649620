import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const SSubtitle = styled.h2`
  text-align: left;
  font-size: 1.75rem;
  font-family: 'Abhaya Libre', serif;
  font-weight: bold;
  color: ${COLORS.LIGHT_BLACK};
  margin-bottom: 1.25rem;
`;

export const Subtitle = ({ children }) => <SSubtitle>{children}</SSubtitle>;

Subtitle.propTypes = {
  children: node.isRequired,
};
