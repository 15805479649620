import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const SBoldText = styled.p`
  font-weight: bold;
  margin-top: 0.5rem;
`;

const SuccessModal = ({ closeModal, email }) => {
  return (
    <div>
      <ModalBackground onClick={closeModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={closeModal}>&times;</CloseButton>
          <ModalTitle>Wysłano</ModalTitle>
          <p>Voucher został wysłany na adres:</p>
          <SBoldText>{email}</SBoldText>
        </ModalContent>
      </ModalBackground>
    </div>
  );
};

export default SuccessModal;
