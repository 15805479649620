import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';

const SList = styled.ul`
  margin: 0 0 20px;
  padding: 0;
  list-style-position: inside;
`;

export const List = ({ children }) => <SList>{children}</SList>;

List.propTypes = {
  children: node.isRequired,
};
