import React from 'react';
import { VoucherView } from 'views/voucher';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags-lexicon';
import { Layout } from 'layouts';

const VoucherPage = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  return (
    <Layout>
      <VoucherView email={email} />
    </Layout>
  );
};

export default VoucherPage;

export const Head = () => <SEO tags={TAGS['regulamin-akcji-voucher']} />;
